body {
  margin: 0;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-pro-card {
  border: 1px solid var(--Grey-button, #e0e0e0);
  border-radius: 6px;
}

.ant-table-thead .ant-table-cell {
  background-color: white !important;
}

@font-face {
  font-family: "GenSekiGothic";
  src: url("./assets/fonts/GenSekiGothic-L.ttc") format("truetype");
  font-weight: 300; /* Light */
}

@font-face {
  font-family: "GenSekiGothic";
  src: url("./assets/fonts/GenSekiGothic-R.ttc") format("truetype");
  font-weight: 400; /* Regular */
}

@font-face {
  font-family: "GenSekiGothic";
  src: url("./assets/fonts/GenSekiGothic-M.ttc") format("truetype");
  font-weight: 500; /* Medium */
}

@font-face {
  font-family: "GenSekiGothic";
  src: url("./assets/fonts/GenSekiGothic-B.ttc") format("truetype");
  font-weight: 700; /* Bold */
}

@font-face {
  font-family: "GenSekiGothic";
  src: url("./assets/fonts/GenSekiGothic-H.ttc") format("truetype");
  font-weight: 900; /* Heavy */
}

:root {
  font-family: "GenSekiGothic";
}
